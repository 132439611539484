import { Injectable } from '@angular/core';
import { LoadPermissionListParams } from '@fca-app/api/fca/permissions-admin/params/load-permission-list.params';
import { PermissionsAdminApiService } from '@fca-app/api/fca/permissions-admin/permissions-admin-api.service';
import { ContentCreatorRequestListResponse } from '@fca-app/api/fca/permissions-admin/response/content-creator-request-list.response';
import { FcaUsersApiService } from '@fca-app/api/fca/users/fca-users-api.service';
import { UserApiResponse } from '@fca-app/api/fca/users/interfaces/response/user-api.response';
import { RequestToBeContentCreatorListFactory } from '@fca-app/models/content-creators/request-to-be-content-creator-list/request-to-be-content-creator-list.factory';
import { RequestToBeContentCreatorListMapper } from '@fca-app/models/content-creators/request-to-be-content-creator-list/request-to-be-content-creator-list.mapper';
import { RequestToBeContentCreatorListModel } from '@fca-app/models/content-creators/request-to-be-content-creator-list/request-to-be-content-creator-list.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ContentCreatorService {
    constructor(
        private permissionsAdminApiService: PermissionsAdminApiService,
        private fcaUsersApiService: FcaUsersApiService
    ) {}

    loadPermissionsList(body: LoadPermissionListParams): Observable<RequestToBeContentCreatorListModel[]> {
        return this.permissionsAdminApiService.loadPermissionsList(body).pipe(
            map((rawData: ContentCreatorRequestListResponse[]) => {
                return rawData.reduce<RequestToBeContentCreatorListModel[]>((acc, raw) => {
                    acc.push(
                        new RequestToBeContentCreatorListFactory().getModelFromData(
                            new RequestToBeContentCreatorListMapper().mapData(raw)
                        )
                    );
                    return acc;
                }, []);
            })
        );
    }

    approveContentCreatorRequest(id: string): Observable<void> {
        return this.permissionsAdminApiService.approveContentCreatorRequest(id);
    }

    rejectContentCreatorRequest(id: string): Observable<void> {
        return this.permissionsAdminApiService.rejectContentCreatorRequest(id);
    }

    getProfileInfo(id: number): Observable<UserApiResponse> {
        return this.fcaUsersApiService.getProfileInfo(id);
    }
}
