import {
    RequestToBeContentCreatorListModel,
    RequestToBeContentCreatorListModelData,
} from '@fca-app/models/content-creators/request-to-be-content-creator-list/request-to-be-content-creator-list.model';
import { ModelFactory } from '@fca-app/shared/abstract/model-factory.abstract';

export class RequestToBeContentCreatorListFactory extends ModelFactory<
    RequestToBeContentCreatorListModelData,
    RequestToBeContentCreatorListModel
> {
    protected getInstance(data: RequestToBeContentCreatorListModelData): RequestToBeContentCreatorListModel {
        return new RequestToBeContentCreatorListModel();
    }
}
