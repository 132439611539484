import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'urlFromText',
})
export class UrlFromTextPipe implements PipeTransform {
    transform(value: string): string {
        const linkRegex = /(\bhttps?:\/\/[^\s]+|\bwww\.[-a-z0-9]+(\.[-a-z0-9]+)*\.[a-z]{2,4}(\/[^/]+)*\b)/gi;
        return value.replace(linkRegex, "<a target='_blank' href='$1'>$1</a>");
    }
}
