<nz-layout>
    <nz-card style="width: 100%;" nzTitle="Content creators">
        <nz-card-tab>
            <nz-tabset (nzSelectChange)='tabChange($event)' nzSize="large" [(nzSelectedIndex)]="tabindex">
                <!--PENDING-->
                <nz-tab nzTitle="PENDING">
                    <nz-list class="demo-loadmore-list" *ngIf="pendingRequestList.length; else emptyRequestList">
                        <nz-list-item *ngFor="let item of pendingRequestList; let i = index">
                            <nz-list-item-meta>
                                <nz-list-item-meta-title> {{ i + 1 }}. <span [innerHTML]="item.description | urlFromText "></span> </nz-list-item-meta-title>
                            </nz-list-item-meta>
                            <ul nz-list-item-actions>
                                <nz-list-item-action>
                                    <button nz-button nzType="default" (click)="goToUser(item)">Go to user profile</button>
                                </nz-list-item-action>
                                <nz-list-item-action>
                                    <button nz-button nzType="primary" (click)="confirm(item)">Confirm</button>
                                </nz-list-item-action>
                                <nz-list-item-action>
                                    <button nz-button nzType="primary" nzDanger (click)="reject(item)">Reject</button>
                                </nz-list-item-action>
                            </ul>
                        </nz-list-item>
                        <div class="loadmore" nz-list-load-more>
                            <button
                                nz-button
                                *ngIf="!stopLoadingMorePendingList && pendingRequestList.length"
                                (click)="onLoadMorePending()"
                            >
                                loading more
                            </button>
                        </div>
                    </nz-list>
                </nz-tab>

                <!--APPROVED-->
                <nz-tab nzTitle="APPROVED">
                    <nz-list class="demo-loadmore-list" *ngIf="approvedRequestList.length; else emptyRequestList">
                        <nz-list-item *ngFor="let item of approvedRequestList; let i = index">
                            <nz-list-item-meta nzDescription="">
                              <nz-list-item-meta-title> {{ i + 1 }}. <span [innerHTML]="item.description | urlFromText "></span> </nz-list-item-meta-title>
                            </nz-list-item-meta>
                            <ul nz-list-item-actions>
                                <nz-list-item-action>
                                    <button nz-button nzType="default" (click)="goToUser(item)">Go to user profile</button>
                                </nz-list-item-action>
                            </ul>
                        </nz-list-item>
                        <div class="loadmore" nz-list-load-more>
                            <button
                                nz-button
                                *ngIf="!stopLoadingMoreApprovedList && approvedRequestList.length"
                                (click)="onLoadMoreApproved()"
                            >
                                loading more
                            </button>
                        </div>
                    </nz-list>
                </nz-tab>

                <!--REJECTED-->
                <nz-tab nzTitle="REJECTED">
                    <nz-list class="demo-loadmore-list" *ngIf="rejectRequestList.length; else emptyRequestList">
                        <nz-list-item *ngFor="let item of rejectRequestList; let i = index">
                            <nz-list-item-meta>
                              <nz-list-item-meta-title> {{ i + 1 }}. <span [innerHTML]="item.description | urlFromText "></span> </nz-list-item-meta-title>
                            </nz-list-item-meta>
                            <ul nz-list-item-actions>
                                <nz-list-item-action>
                                    <button nz-button nzType="default" (click)="goToUser(item)">Go to user profile</button>
                                </nz-list-item-action>
                            </ul>
                        </nz-list-item>
                        <div class="loadmore" nz-list-load-more>
                            <button
                                nz-button
                                *ngIf="!stopLoadingMoreRejectList && rejectRequestList.length"
                                (click)="onLoadMoreRejected()"
                            >
                                loading more
                            </button>
                        </div>
                    </nz-list>
                </nz-tab>
            </nz-tabset>
        </nz-card-tab>
    </nz-card>
</nz-layout>

<ng-template #emptyRequestList>
    <p class="empty-request-list">
        <strong>Empty request list</strong>
    </p>
</ng-template>
