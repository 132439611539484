import { Injectable } from '@angular/core';
import { FcaApiServiceBase } from '@fca-app/api/fca/fca-api-service-base.service';
import { LoadPermissionListParams } from '@fca-app/api/fca/permissions-admin/params/load-permission-list.params';
import { ContentCreatorRequestListResponse } from '@fca-app/api/fca/permissions-admin/response/content-creator-request-list.response';
import { Observable } from 'rxjs';
import { publishReplay, refCount } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class PermissionsAdminApiService extends FcaApiServiceBase {
    loadPermissionsList(body: LoadPermissionListParams): Observable<ContentCreatorRequestListResponse[]> {
        return this.http
            .post<ContentCreatorRequestListResponse[]>(`${this.apiUrl}/permissions/admin/list`, body)
            .pipe(publishReplay(1), refCount());
    }

    approveContentCreatorRequest(id: string): Observable<void> {
        return this.http
            .post<void>(`${this.apiUrl}/permissions/admin/approve`, { id })
            .pipe(publishReplay(1), refCount());
    }

    rejectContentCreatorRequest(id: string): Observable<void> {
        return this.http
            .post<void>(`${this.apiUrl}/permissions/admin/reject`, { id })
            .pipe(publishReplay(1), refCount());
    }
}
