import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { LoadPermissionListParams } from '@fca-app/api/fca/permissions-admin/params/load-permission-list.params';
import { UserApiResponse } from '@fca-app/api/fca/users/interfaces/response/user-api.response';
import { RequestToBeContentCreatorListModel } from '@fca-app/models/content-creators/request-to-be-content-creator-list/request-to-be-content-creator-list.model';
import { ContentCreatorService } from '@fca-app/services/content-creator.service';
import { PermissionsStatusEnum } from '@fca-app/shared/enums/permissions-status.enum';
import { UntilDestroy, untilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTabChangeEvent } from 'ng-zorro-antd/tabs';
import { take, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'app-content-creators-list',
    templateUrl: './content-creators-list.component.html',
    styleUrls: ['./content-creators-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentCreatorsListComponent implements OnInit {
    loadApprovedPermissionListParams: LoadPermissionListParams = {
        page: 0,
        limit: 10,
        types: ['CONTENT_CREATOR'],
        statuses: [PermissionsStatusEnum.APPROVED],
        userIds: [],
    };
    loadRejectPermissionListParams: LoadPermissionListParams = {
        page: 0,
        limit: 10,
        types: ['CONTENT_CREATOR'],
        statuses: [PermissionsStatusEnum.REJECTED],
        userIds: [],
    };
    loadPendingPermissionListParams: LoadPermissionListParams = {
        page: 0,
        limit: 10,
        types: ['CONTENT_CREATOR'],
        statuses: [PermissionsStatusEnum.PENDING],
        userIds: [],
    };
    tabindex = 0;
    approvedRequestList: RequestToBeContentCreatorListModel[] = [];
    rejectRequestList: RequestToBeContentCreatorListModel[] = [];
    pendingRequestList: RequestToBeContentCreatorListModel[] = [];
    stopLoadingMoreApprovedList = false;
    stopLoadingMoreRejectList = false;
    stopLoadingMorePendingList = false;

    constructor(
        private contentCreatorService: ContentCreatorService,
        private _cdr: ChangeDetectorRef,
        private _router: Router,
        private _route: ActivatedRoute,
        private notification: NzNotificationService
    ) {
        this._route.queryParams
            .pipe(
                untilDestroy(this),
                tap((params: Params) => {
                    this.tabindex = params['tabIndex'];
                    this._cdr.detectChanges();
                })
            )
            .subscribe();
    }

    ngOnInit(): void {
        this.loadApprovedRequests();
        this.loadRejectRequests();
        this.loadPendingRequests();
    }

    loadApprovedRequests() {
        this.contentCreatorService
            .loadPermissionsList(this.loadApprovedPermissionListParams)
            .pipe(
                tap((response: RequestToBeContentCreatorListModel[]) => {
                    this.approvedRequestList.push(...response);
                    this.stopLoadingMoreApprovedList = response.length < this.loadApprovedPermissionListParams.limit;
                    this._cdr.detectChanges();
                })
            )
            .subscribe();
    }

    loadRejectRequests() {
        this.contentCreatorService
            .loadPermissionsList(this.loadRejectPermissionListParams)
            .pipe(
                tap((response: RequestToBeContentCreatorListModel[]) => {
                    this.rejectRequestList.push(...response);
                    this.stopLoadingMoreRejectList = response.length < this.loadApprovedPermissionListParams.limit;
                    this._cdr.detectChanges();
                })
            )
            .subscribe();
    }

    loadPendingRequests() {
        this.contentCreatorService
            .loadPermissionsList(this.loadPendingPermissionListParams)
            .pipe(
                tap((response: RequestToBeContentCreatorListModel[]) => {
                    this.pendingRequestList.push(...response);
                    this.stopLoadingMorePendingList = response.length < this.loadApprovedPermissionListParams.limit;
                    this._cdr.detectChanges();
                })
            )
            .subscribe();
    }

    confirm(item: RequestToBeContentCreatorListModel) {
        this.contentCreatorService
            .approveContentCreatorRequest(item.id)
            .pipe(
                take(1),
                untilDestroy(this),
                tap(() => {
                    this.pendingRequestList = this.pendingRequestList.filter(
                        (filteredItem: RequestToBeContentCreatorListModel) => filteredItem.id !== item.id
                    );
                    this.loadApprovedPermissionListParams.page = 0;
                    this.approvedRequestList = [];
                    this.createNotification('This request was confirmed');
                    this._cdr.detectChanges();
                    this.loadApprovedRequests();
                })
            )
            .subscribe();
    }

    reject(item: RequestToBeContentCreatorListModel) {
        this.contentCreatorService
            .rejectContentCreatorRequest(item.id)
            .pipe(
                take(1),
                untilDestroy(this),
                tap(() => {
                    this.pendingRequestList = this.pendingRequestList.filter(
                        (filteredItem: RequestToBeContentCreatorListModel) => filteredItem.id !== item.id
                    );
                    this.loadRejectPermissionListParams.page = 0;
                    this.rejectRequestList = [];
                    this.createNotification('This request was rejected');
                    this._cdr.detectChanges();
                    this.loadRejectRequests();
                })
            )
            .subscribe();
    }

    createNotification(title: string) {
        this.notification.success(title, '', { nzPlacement: 'bottomRight' });
    }

    onLoadMoreApproved() {
        if (this.stopLoadingMoreApprovedList) {
            return;
        }
        this.loadApprovedPermissionListParams.page++;
        this.loadApprovedRequests();
    }

    onLoadMoreRejected() {
        if (this.stopLoadingMoreRejectList) {
            return;
        }
        this.loadRejectPermissionListParams.page++;
        this.loadRejectRequests();
    }

    onLoadMorePending() {
        if (this.stopLoadingMorePendingList) {
            return;
        }
        this.loadPendingPermissionListParams.page++;
        this.loadApprovedRequests();
    }

    goToUser(item: RequestToBeContentCreatorListModel) {
        this.contentCreatorService
            .getProfileInfo(item.userId)
            .pipe(
                take(1),
                untilDestroy(this),
                tap((response: UserApiResponse) => {
                    switch (response.roleName) {
                        case 'FAN':
                            this._router.navigate(['/dashboard/fans', response.id]);
                            break;
                        case 'ADMIN':
                            this._router.navigate(['/dashboard/fans', response.id]);
                            break;
                        case 'FIGHTER':
                            this._router.navigate(['/dashboard/fighters', response.id]);
                            break;
                        case 'LOCATION_PROVIDER':
                            this._router.navigate(['/dashboard/arenas', response.id]);
                            break;
                    }
                })
            )
            .subscribe();
    }

    tabChange(event: NzTabChangeEvent) {
        this._router.navigate([], {
            queryParams: {
                tabIndex: event.index,
            },
            queryParamsHandling: 'merge',
        });
    }
}
