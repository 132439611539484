import { ContentCreatorRequestListResponse } from '@fca-app/api/fca/permissions-admin/response/content-creator-request-list.response';
import { RequestToBeContentCreatorListModelData } from '@fca-app/models/content-creators/request-to-be-content-creator-list/request-to-be-content-creator-list.model';
import { ModelDataMapper } from '@fca-app/shared/interfaces/model-data-mapper.interface';

export class RequestToBeContentCreatorListMapper
    implements ModelDataMapper<ContentCreatorRequestListResponse, RequestToBeContentCreatorListModelData> {
    mapData(raw: ContentCreatorRequestListResponse): RequestToBeContentCreatorListModelData {
        return {
            description: raw.description,
            id: raw.id,
            status: raw.status,
            type: raw.type,
            userId: raw.userId,
        };
    }
}
